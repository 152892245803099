<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mt-3"
      >
        <h4>Dados de acesso</h4>
        <fieldset class="font-weight-bold text-muted small">
          Informações para acessar o sistema
        </fieldset>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </b-col>
    </b-row>
    <b-row>
      <b-form-group
        label="Login (e-mail)"
        label-for="login"
        class="col-md-6"
      >
        <validation-provider
          #default="{ errors }"
          name="Login"
          rules="required|email"
        >
          <b-form-input
            :id="'login'"
            ref="login"
            v-model="valueInner.login"
            autocomplete="new-password"
            :state="(errors.length > 0 || emailFeedback) ? false :null"
            @blur="validaLogin"
            @input="enviarPai"
          />
          <small class="text-danger">{{ errors[0] || emailFeedback }}</small>
        </validation-provider>
      </b-form-group>
    </b-row>
    <b-row>
      <b-col
        cols="6"
      >
        <b-form-group
          label="Senha"
          label-for="senha"
        >
          <senha-input
            :id="'senha'"
            v-model="valueInner.senha"
            :mostrar-senha="false"
            auto-complete-senha="new-password"
            :required="senhaObrigatoria"
            @input="enviarPai"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label-for="confirmarSenha"
          label="Confirmar Senha"
        >
          <senha-input
            :id="'confirmarSenha'"
            v-model="valueInner.confirmarSenha"
            :mostrar-senha="false"
            auto-complete-senha="check-password"
            :required="!!valueInner.senha && valueInner.senha !== 'fakepass'"
            @input="enviarPai"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-checkbox
          v-model="valueInner.alterarSenha"
          size="sm"
          value="true"
          unchecked-value="false"
          class="detail-check-sm"
          @input="alterarSenha"
        >
          Alterar senha no próximo acesso
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row
      class="pb-2"
      :style="isCadastroModal ? 'padding-top:0.8rem':''"
    >
      <b-col cols="6" />
    </b-row>
  </div>
</template>

<style lang="scss">

.detail-check-sm.custom-checkbox.b-custom-control-sm
  .custom-control-label::before,
.detail-check-sm.input-group-sm .custom-checkbox .custom-control-label::before {
  left: 0px !important;
}

.detail-check-sm.custom-checkbox.b-custom-control-sm .custom-control-label {
  font-size: 0.95rem !important;
}
</style>

<script>

import {
  required, email,
} from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'DadosAcesso',
  components: {
    ValidationProvider,
    SenhaInput: () => import('../senha-input/SenhaInput.vue'),
  },
  props: {
    value: {
      type: Object,
      default: () => { },
    },
    validaLogin: {
      type: Function,
      required: true,
    },
    emailFeedback: {
      type: String,
      default: null,
    },
    isCadastroModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email,
      required,
      senhaObrigatoria: false,
      valueInner: {},
    }
  },
  computed: {
    validarSenha() {
      return !this.valueInner.alterarSenha
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueInner = value
      }
    },
  },
  created() {
    this.valueInner = this.value
    this.senhaObrigatoria = !this.valueInner.alterarSenha
  },
  methods: {
    enviarPai() {
      this.$emit('input', this.valueInner)
    },
    alterarSenha() {
      this.senhaObrigatoria = !this.valueInner.alterarSenha
      this.enviarPai()
    },
    valido() {
      // criar validação no vee-validate
      if (this.valueInner.senha !== 'fakepass' && this.valueInner.senha !== this.valueInner.confirmarSenha) {
        return false
      }
      return true
    },
  },

}
</script>
